import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    *,
    *::after,
    *::before {
        box-sizing: border-box
    }

body {
    background-color: ${({theme}) => theme.body};
    font-family: 'Barlow Condensed', sans-serif;
    color: ${({theme}) => theme.text};
}

h1, h2, h3, h4, h5, h6, .h4 {
    color: ${({theme}) => theme.text};
    text-transform: uppercase;
}

label {
    color: ${({theme}) => theme.text};

}

a, a:hover {
    color: ${({theme}) => theme.text};
}

.card {
    background-color: ${({theme}) => theme.card};
}

.slick-track {
    margin: 0;
}

nav .dropdown-menu {
    background-color: ${({theme}) => theme.nav};
}

.list-group-item {
    background-color: ${({theme}) => theme.nav};
}

.modal-content {
    background-color: ${({theme}) => theme.card}
}

.form-control {
    background-color: ${({theme}) => theme.card};
    color: ${({theme}) => theme.text};
}

.form-control:focus {
    background-color: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
}
`