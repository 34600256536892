import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import Confirm from "./../Alert/Confirm";
import QuizzAlternative from "./../Quizz/Alternative";
import { ReactComponent as ArrowLeft } from "./../../images/icons/arrow-left.svg";

function Question(props) {
  const [input, setInput] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [feedback, setFeedback] = useState("");

  const saveAnswerIntoUserSession = () => {
    const session =
      JSON.parse(sessionStorage.getItem("@legal-cases/quiz")) || {};
    const answer = {
      pergunta_id: props.id,
      pergunta: props.title,
      resposta: input,
    };

    session[props.id] = answer;

    sessionStorage.setItem("@legal-cases/quiz", JSON.stringify(session));
  };

  const handleNextQuestion = () => {
    saveAnswerIntoUserSession();
    props.goOneQuestionForward();
  };

  const handleSaveAnswers = () => {
    setOpenDialog(false);
    handleNextQuestion();
    props.handleSaveAnswers();
  };

  const handleChange = (e, feedback) => {
    setInput({
      "id": e.currentTarget.getAttribute("data-id"),
      "resposta": e.currentTarget.value
    });

    Number(props.validate) && setFeedback(feedback);
  };

  return (
    <>
      <Confirm
        open={openDialog}
        title="Você realmente deseja salvar suas respostas?"
        body="As respostas não poderão ser alteradas após essa ação!"
        onConfirm={handleSaveAnswers}
        onDeny={() => setOpenDialog(false)}
        deny="Cancelar"
        confirm="Confirmar"
      />

      <Confirm
        open={openFeedbackDialog}
        title="Feedback"
        deny="Tentar Novamente"
        confirm="Confirmar"
        body={
          <>
            <h4>{feedback}</h4>
            <hr />
            <h5>Você deseja realmente confirmar sua resposta?</h5>
          </>
        }
        onConfirm={() => {
          setOpenFeedbackDialog(false);
          setFeedback("");
          handleNextQuestion();
        }}
        onDeny={() => {
          setOpenFeedbackDialog(false);
        }}
      />

      <Card text="white" className="mb-2">
        <Card.Body>
          <div className="text-center">
            <h1>{props.title}</h1>
            <div className="mt-5 text-left">
              {props.alternatives.map((alternative) => (
                <QuizzAlternative
                  id={alternative.id}
                  type={alternative.categoria.tipo_campo}
                  description={alternative.descricao}
                  handleChange={handleChange}
                  feedback={alternative.feedback}
                />
              ))}
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="text-right">
          {props.currentQuestion > 0 && Number(props.validate) && (
            <Button
              onClick={props.goOneQuestionBack}
              variant="default"
              className="mr-2"
            >
              <ArrowLeft />
            </Button>
          )}
          {
            feedback ? (
              <Button variant="dark" onClick={() => setOpenFeedbackDialog(true)}>
                Verificar Resposta
              </Button>
            ) : (
                props.lastQuestion ? (
                  <Button variant="dark" onClick={() => setOpenDialog(true)}>
                    Salvar Respostas
                  </Button>
                ) : (
                    <Button variant="dark" onClick={handleNextQuestion}>
                      Próxima Pergunta
                    </Button>
                  )
              )
          }
        </Card.Footer>
      </Card>
    </>
  );
}

export default Question;
