import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import {ReactComponent as BugIcon} from '../../images/icons/bug.svg';
import { reportBug } from '../../http';

function BugReportModal() {
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);

    const report = e => {
        e.preventDefault();
        reportBug({ title, description }).then(() => {
            alert('Bug reportado!');
        });
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleTitleChange = e => setTitle(e.target.value);
    const handleDescriptionChange = e => setDescription(e.target.value);

    const modal = (
        <Modal
            backdrop='static'
            show={show}
            style={{ color: 'rgb(34,34,34)' }}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Reportar Bug</Modal.Title>
            </Modal.Header>
            <Form onSubmit={report}>
                <Modal.Body>
                    <Form.Group controlId='reportBug.titulo'>
                        <Form.Label>Título</Form.Label>
                        <Form.Control type='text' onChange={handleTitleChange} />
                    </Form.Group>
                    <Form.Group controlId='reportBug.description'>
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control as='textarea' rows={4} onChange={handleDescriptionChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' variant='primary'>Reportar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );

    return (
        <>
            <Button variant='danger' className='mr-1' onClick={handleShow}>
                <BugIcon />
            </Button>
            { modal}
        </>
    );
}

export default BugReportModal;