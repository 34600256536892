import React, { useState } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import Confirm from './../Alert/Confirm'
import { storePetitionContent, savePetitionAsPdf } from './../../http'
import { useHistory } from 'react-router-dom'

function SavePetition (props) {
  const [openDialog, setOpenDialog] = useState(false)
  const [moldes, setMoldes] = useState()
  const history = useHistory()

  const saveIntoDatabase = () => {
    const { tipo_documento } = props
    storePetitionContent({
      id: props.id,
      answeres: props.petition,
      tipo_documento,
      titulo: props?.caso?.titulo
    }).then(({ data }) => {
      setMoldes(data)
    }).catch(error => console.debug(error))
  }

  const saveAsPDF = () => {
    savePetitionAsPdf({ peticao: props.petition }).then((response) => {
      const pdf = new Blob([response], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdf)

      window.open(pdfUrl)
    })
  }

  if (moldes) {
    return (
      <>
        <h3>Feedback</h3>
        <hr />
        <ol>
          {
          moldes.map(molde => (
            <li>{molde.categoria} - {molde.feedback || `documento sem feedback!`}</li>
          ))
        }
        </ol>
        <div>
          <Button className='btn-block' onClick={() => history.goBack()}>Voltar</Button>
        </div>
      </>
    )
  } else {
    return (
      <>
        <Confirm
          open={openDialog}
          title={`Você realmente deseja salvar o documento?`}
          body='O seu documento será salvo logo após essa ação!'
          deny='Cancelar'
          confirm='Confirmar'
          onConfirm={() => {
            saveIntoDatabase()
            setOpenDialog(false)
          }}
          onDeny={() => setOpenDialog(false)}
        />

        <Card className='mb-2'>
          <Card.Body>
            <Row>
              <Col>
                <Card.Text>
                  Você acabou de concluir o documento, agora você pode selecionar
                  uma das opções disponíveis abaixo:{' '}
                </Card.Text>
              </Col>
            </Row>
            <Row className='mt-4 mb-3'>
              <Col className='text-right'>
                <Button variant='dark' onClick={() => setOpenDialog(true)}>
                  Salvar documento
                </Button>
              </Col>
              <Col className='text-left'>
                <Button variant='dark' onClick={saveAsPDF}>
                  Baixar cópia em PDF
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    )
  }
}

export default SavePetition
