import React, { useState } from "react";
import Question from "./../../components/Quizz/Question";
import { useHistory } from "react-router-dom";
import { storeQuizzAnswers } from "./../../http";

function Quizz(props) {
  const history = useHistory();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const { perguntas } = props.data;

  const handleSaveAnswers = () => {
    const answers = sessionStorage.getItem("@legal-cases/quiz");
    const username = localStorage.getItem("@legal-cases/username");

    answers &&
      storeQuizzAnswers({
        usuario: 1,
        nome_aluno: username,
        json_respostas_perguntas: answers
      }).then(({ data }) => {
        const local = JSON.parse(localStorage.getItem("@legal-cases/quiz-done")) || [];
        local.push(Number(props.data.quizz.id));
        localStorage.setItem("@legal-cases/quiz-done", JSON.stringify(local));

        let scoreStorage = JSON.parse(localStorage.getItem("@legal-cases/score")) || {};

        const key = Object.keys(data)[0];
        const value = Object.values(data)[0];

        if(Object.keys(scoreStorage).includes(key)) {
          scoreStorage[key] += value;
        } else {
          scoreStorage = Object.assign(data, scoreStorage);
        }
        
        localStorage.setItem("@legal-cases/score", JSON.stringify(scoreStorage));

        history.goBack();
      });
  };

  const goOneQuestionBack = () => {
    currentQuestion > 0 && setCurrentQuestion(currentQuestion - 1);
  };

  const goOneQuestionForward = () => {
    currentQuestion < perguntas.length - 1 &&
      setCurrentQuestion(currentQuestion + 1);
  };

  return (
    <Question
      id={perguntas[currentQuestion].id}
      title={perguntas[currentQuestion].titulo}
      score={perguntas[currentQuestion].pontuacao}
      alternatives={perguntas[currentQuestion].alternativas}
      validate={props.data.quizz.validar_quizz}
      currentQuestion={currentQuestion}
      lastQuestion={perguntas.length - 1 === currentQuestion}
      goOneQuestionForward={goOneQuestionForward}
      goOneQuestionBack={goOneQuestionBack}
      handleSaveAnswers={handleSaveAnswers}
    />
  );
}

export default Quizz;
