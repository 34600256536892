import React from "react";
import { ListGroup } from "react-bootstrap";

function Board(props) {
  const score = JSON.parse(localStorage.getItem("@legal-cases/score")) || {};

  return (
    <>
      <div className="border p-3">
        <div className="text-center">
          <h2>Quadro Resumido</h2>
        </div>
        {props.categories.map((category) => (
          <ListGroup>
            <ListGroup.Item>
              {category.nome}
              <span className="float-right">
                { score[category.id] || 0 } Ponto/s
              </span>
            </ListGroup.Item>
          </ListGroup>
        ))}
      </div>
    </>
  );
}

export default Board;