import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function Category(props) {
  const [quizDone, setQuizDone] = useState([]);

  useEffect(() => {
    const local = localStorage.getItem("@legal-cases/quiz-done");
    setQuizDone(JSON.parse(local) || []);
  }, []);

  return (
    <div className="mb-2">
      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0" style={{ cursor: "pointer" }}>
            {props.category}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <ul className="list-unstyled">
                {props.quizzes.map((quizz) => (
                  !quizDone.includes(Number(quizz.id)) &&
                  <li key={`list-quiz-${quizz.id}`}>
                    <Link
                      key={`link-quiz-${quizz.id}`}
                      to={`/quizz/${quizz.id}`}
                    >
                      {quizz.titulo}
                    </Link>
                  </li>
                ))}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default Category;
