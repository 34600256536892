import React, { useEffect, useState } from 'react'
import { Case, CaseCategory, Loading, Error } from './../components'
import { Row, Col, Form } from 'react-bootstrap'
import SearchResults from 'react-filter-search'
import { Link } from 'react-router-dom'
import { findCases } from './../http'

const scroll = {
  width: '1400px',
  overflowX: 'auto',
}

function Home() {
  const [cases, setCases] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    findCases().then(({ data, status }) => {
      status === 'error' ? setError(true) : setCases(data)

      setLoading(false)
    }).catch((e) => console.debug(e))
  }, [])

  if (loading) {
    return <Loading animation='grow' />
  }

  if (error) {
    return <Error title='Oops... Algo deu errado!' message={error} />
  }

  return (
    <div>
      <Form className='mb-5'>
        <Form.Control
          type='text'
          placeholder='Pesquisar'
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
      </Form>
      <SearchResults
        value={search}
        data={cases}
        renderResults={results => results.map((category) => (
          <CaseCategory
            key={`category-${category.id}`}
            categoryId={category.id}
            name={category.nome}
          >
            <Row>
              <Col>
                <div style={scroll} className='d-flex align-items-stretch'>
                  {category.casos.map((caso) => {
                    if (caso.descricao.includes(search) || caso.titulo.includes(search)) {

                      return (
                        <Link
                          key={`link-case-${caso.id}`}
                          to={`/case/${caso.id}`}
                          className='text-decoration-none'
                        >
                          <Case
                            key={`case-${caso.id}`}
                            title={caso.titulo}
                            description={caso.descricao}
                            image={caso?.imagem}
                          />
                        </Link>
                      )
                    }
                  })}
                </div>
              </Col>
            </Row>
          </CaseCategory>
        ))}
      />
    </div>
  )
}

export default Home
