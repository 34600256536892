import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import Home from '../pages/Home'
import HomeCategory from '../pages/HomeCategory'
import Case from '../pages/Case'
import QuizzList from '../pages/QuizzList'
import Quizz from '../pages/Quizz'
import Petition from '../pages/Petition'

function routes () {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/category/:id' exact component={HomeCategory} />
        <Route path='/case/:id' exact component={Case} />
        <Route path='/case/:id/quizzes' exact component={QuizzList} />
        <Route path='/case/:id/petition' exact component={Petition} />
        <Route path='/quizz/:id' exact component={Quizz} />
      </Switch>
    </Router>
  )
}

export default routes
