import React from 'react';
import { Alert, Image } from 'react-bootstrap';

function AlertError(props) {
    return (
        <Alert style={{ padding: '0px' }}>
            <Alert.Heading>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-exclamation-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                </svg>
                &nbsp; {props.title}
            </Alert.Heading>
            <hr/>
            <div className="text-center">
                <p>{ props?.message }</p>
                <Image 
                    src='https://www.travelappeal.com/hs-fs/hubfs/broken-robot.png?width=383&name=broken-robot.png'
                    style={{ width: '200px', height: '250px' }}
                />
            </div>
        </Alert>
    );
}

export default AlertError;