import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loading(props) {
    const settings = {
        style: {
            width: props.size + 'em',
            height: props.size + 'em'
        },
        animation: props.animation,
        message: props.message || 'Loading...'
    };

    return (
        <div className='text-center'>
            <Spinner animation={settings.animation} style={settings.style} />
            <h3>{settings.message}</h3>
        </div>
    );
}

export default Loading;