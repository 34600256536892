import React from 'react'

import { Link } from 'react-router-dom'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function Category (props) {
  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div className='mb-4'>
      <Link
        to={`/category/${props.categoryId}`}
        className='text-decoration-none'
      >
        <h3>{props.name}</h3>
      </Link>
      <Slider {...settings}>
        {props.children || (<p className='lead'>AINDA NÃO HÁ CASOS.</p>)}
      </Slider>
    </div>
  )
}

export default Category
