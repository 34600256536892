import React, { useState } from 'react';
import Nav from './Navbar/Navbar';
import { Container } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from '../themes/themes';
import { GlobalStyle } from '../themes/globalstyle';

function App({ children }) {
  const [ darkMode, setDarkMode ] = useState(Number(localStorage.getItem('@legal-cases/darkMode')));

  const handleDarkMode = (value) => {
    setDarkMode(Number(value));
    localStorage.setItem('@legal-cases/darkMode', Number(value));
  }

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <GlobalStyle />
      <Nav darkModeHandler={handleDarkMode} />
      <Container fluid className='mt-5 pr-5 pl-5'>

        { children }

      </Container>
    </ThemeProvider>
  );
}

export default App;
