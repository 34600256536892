export const lightTheme = {
    body: '#fff',
    text: '#343a40',
    card: '#f8f9fa',
    nav:  '#fff'
};

export const darkTheme = {
    body: 'rgb(34,34,34)',
    text: '#fff',
    card: 'rgb(51, 51, 51)',
    nav:  '#343a40'
}