import React from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import "./Action.css";

function Action(props) {
  return (
    <div className="action-button">
      <OverlayTrigger
        placement="top"
        delay={{ show: 200, hide: 400 }}
        overlay={<Tooltip>{props.tooltip}</Tooltip>}
      >
        <Button variant={props.color}>
          {props.icon}
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default Action;
