import React from 'react';
import { Card } from 'react-bootstrap';
import ImageNotAvailable from '../../images/defaults.jpe';

function Case(props) {
    const { title, description} = props;
    const linkRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&=]*)/
    const image = linkRegex.test(props.image) ? props.image :  ImageNotAvailable
    return (
        <Card className='mr-2 h-100' style={{ width: "25em" }} data-testid='case-card'>
            <Card.Header>{title}</Card.Header>
            {
                image
                    ? (<Card.Img
                        variant='top'
                        src={image}
                        alt={`Imagem de ${title}`}
                        style={{ height: '16em' }}
                    />)
                    : (<Card.Img
                        variant='top'
                        src={image}
                        alt={`Imagem de ${title}`}
                        style={{ height: '16em' }}
                    />)
            }
            <Card.Body className='text-justify'>
                <Card.Text>
                    {typeof description !== 'string' || description.substring(0, 200)}
                    {typeof description !== 'string' || description.length > 200 ? '...' : ''}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default Case;