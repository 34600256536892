import React from "react";
import { Modal, Button } from "react-bootstrap";

function Confirm(props) {
  return (
    <Modal
      backdrop="static"
      show={props.open}
      style={{ color: "rgb(34,34,34)" }}
    >
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        {
          props.deny && (
            <Button variant="danger" onClick={props.onDeny}>
              {props.deny}
            </Button>
          )
        }
        {
          props.confirm && (
            <Button variant="primary" onClick={props.onConfirm}>
              {props.confirm}
            </Button>

          )
        }
      </Modal.Footer>
    </Modal>
  );
}

export default Confirm;
