import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Loading, Error } from './../components'
import { ReactComponent as NextIcon } from '../images/icons/next.svg'
import { findCase } from './../http'

function Case(props) {
  const [caseData, setCaseData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { id } = props.match.params

  useEffect(() => {
    findCase(id).then(({ data, status }) => {
      status === 'error'
        ? setError(true)
        : setCaseData(data)

      setLoading(false)
    })
  }, [id])

  return (
    loading ? (
      <Loading animation='grow' />
    ) : (
      error
        ? (<Error title='Oops... Algo deu errado!' message={error} />)
        : (
          <>
            <Row className='text-right'>
              <Col>
                <Button variant='dark' size='lg' href={`/case/${id}/Petition`}>
                  Próximo <NextIcon />
                </Button>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h1>{caseData.titulo}</h1>
                {caseData.descricao ? (
                  <p className='lead text-justify'>{caseData.descricao}</p>
                ) : (
                  <p className='lead'>NENHUMA DESCRIÇÃO DESPONÍVEL.</p>
                )}
              </Col>
              {
                caseData.video.link && (
                  <>
                    <hr />
                    <Col md={5}>
                      <iframe
                        width='100%'
                        height='300'
                        title={caseData.title}
                        src={caseData.video.link}
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                      />
                    </Col>
                  </>
                )
              }
            </Row>
            {
              caseData.texto.link && (
                <>
                  <hr />
                  <div>
                    <embed
                      src={`${caseData.texto.link}#view=FitH`}
                      width='100%'
                      height='800'
                    />
                  </div>
                </>
              )
            }
          </>
        )
    )
  )
}

export default Case
