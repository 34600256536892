import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { PetitionMolds, SavePetition, Loading, Error } from './../components'
import ReactQuill from 'react-quill'
import { findPetitionByCase } from './../http'

import 'react-quill/dist/quill.snow.css'

function Petition (props) {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [molds, setMolds] = useState([])
  const [petition, setPetition] = useState(null)
  const [tipo_documento, settipo_documento] = useState('')
  const [content, setContent] = useState('')
  const [answere, setAnswere] = useState([])

  const { id } = props.match.params

  const handleClickOnMold = ({ currentTarget }) => {
    const currentContent = `<h3><b>${molds[0][0]}:</b></h3> ${currentTarget.value} <br/><br/>`

    setAnswere([
      ...answere,
      {
        answere: currentContent,
        molde_id: currentTarget.getAttribute('data-id')
      }
    ])

    setContent(content.concat(currentContent))

    molds.shift()
  }

  useEffect(() => {
    findPetitionByCase(id).then(({ data, status }) => {
      if (status === 'error') {
        setError(data)
      } else {
        setMolds(Object.entries(data.moldes))
        setPetition(data.peticao.id)
        settipo_documento(data.peticao.tipo_documento)
        
      }
      setLoading(false)
    })
  }, [id])

  return loading
    ? <Loading animation='grow' />
    : error
      ? <Error title='Oops... Algo deu errado!' message={error} />
      : <>
        <Row>
          <Col>
            <h1>{tipo_documento}</h1>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <ReactQuill theme='snow' value={content} onChange={setContent} />
          </Col>
          <Col>
            {molds.length > 0
              ? <PetitionMolds
                  category={molds[0][0]}
                  options={molds[0][1]}
                  handleClickOnMold={handleClickOnMold}
                />
              : <SavePetition id={petition} petition={answere} />}
          </Col>
        </Row>
      </>
}

export default Petition
