const apiOrigin = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_API_TOKEN;

export function findCases() {
    return fetch(`${apiOrigin}/rest.php?class=casoRestService&method=loadAll`, {
        'method': 'get',
        'headers': {
            'Authorization': token
        }
    }).then(res => res.json());
}

export function findCase(id) {
    return fetch(`${apiOrigin}/rest.php?class=casoRestService&method=load&id=${id}`, {
        'method': 'get',
        'headers': {
            'Authorization': token
        }
    }).then(res => res.json());
}

export function findQuizzesByCase(id) {
    return fetch(`${apiOrigin}/rest.php?class=casoRestService&method=loadQuizzesByCase&id=${id}`, {
        'method': 'get',
        'headers': {
            'Authorization': token
        }
    }).then(response => response.json());
}

export function findQuizzesByCategoryCase(id) {
    return fetch(`${apiOrigin}/rest.php?class=CategoriaQuizzRestService&method=loadQuizzesByCase&id=${id}`, {
        'method': 'get',
        'headers': {
            'Authorization': token
        }
    }).then(res => res.json());
}

export function findQuizz(id) {
    return fetch(`${apiOrigin}/rest.php?class=QuizzRestService&method=load&id=${id}`, {
        'method': 'get',
        'headers': {
            'Authorization': token
        }
    }).then(res => res.json());
}

export function findPetitionByCase(id) {
    return fetch(`${apiOrigin}/rest.php?class=casoRestService&method=loadPetitionByCase&id=${id}`, {
        'method': 'get',
        'headers': {
            'Authorization': token
        }
    }).then(res => res.json());
}

export function storeQuizzAnswers(answers) {
    return fetch(`${apiOrigin}/rest.php?class=RespostaQuizzRestService&method=store`, {
        'method': 'post',
        'headers': {
            'Authorization': token
        },
        'body': JSON.stringify(answers)
    }).then(response => response.json());
}

export function storePetitionContent(content) {
    return fetch(`${apiOrigin}/rest.php?class=PeticaoRestService&method=store`, {
        'method': 'post',
        'headers': {
            'Authorization': token
        },
        'body': JSON.stringify(content)
    }).then(response => response.json());
}

export function savePetitionAsPdf(petition) {
    return fetch(`${apiOrigin}/rest.php?class=PeticaoRestService&method=savePeticaoAsPDF`, {
        'method': 'post',
        'headers': {
            'Authorization': token
        },
        'body': JSON.stringify(petition)
    }).then(response => response.blob());
}

export function reportBug(opt) {
    return fetch(`${apiOrigin}/rest.php?class=AwsRestService&method=sendEmail`, {
        method: 'POST',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'recipient_emails': process.env.REACT_APP_AWS_RECIPIENT_EMAILS,
            'sender_email': process.env.REACT_APP_AWS_SENDER_EMAIL,
            'subject': opt.title,
            'body': opt.description
        })
    }).then(res => res.json());
}