import React from 'react'
import { Card, ListGroup } from 'react-bootstrap'

function ListItem(props) {
  return (<ul ><li>{props.value}</li></ul>)
}

function Molds (props) {
  const removeHtmlTags = value => value.replace(/(&nbsp;|<([^>]+)>)/ig, '')

  const options = props.options.sort((current, next) => {
    const currentContent = removeHtmlTags(current.conteudo)
    const nextContent = removeHtmlTags(next.conteudo) 

    return currentContent.localeCompare(nextContent)
  })
  return (
    <Card className='mb-2'>
      <Card.Header style={{textTransform:'uppercase'}}>{props.category}</Card.Header>
      <Card.Body>
        <ListGroup>
          {
            options.map((option,index) => (
              <ListGroup.Item
              key='mold'
                as='button'
                onClick={props.handleClickOnMold}
                value={option.conteudo}
                data-id={option.id}
              >
                <b>{`Alternativa:`}</b>
              {ListItem(option.conteudo)}
          {removeHtmlTags(option.conteudo)}
              </ListGroup.Item>
            ))
         }
        </ListGroup>
      </Card.Body>
    </Card>
  )
}

export default Molds