import React, { Component } from 'react';
import { Navbar, Dropdown, FormCheck, Image } from 'react-bootstrap';
import BugReportModal from './BugReportModal';
import logo from '../../images/logo.png';

class Nav extends Component {
    render() {
        return (
            <>
                <Navbar style={{ backgroundColor: 'rgb(17,17,17)' }}>
                    <Navbar.Brand>
                        <a href='/'>
                            <Image src={logo} alt="logo" width={130} />
                        </a>
                    </Navbar.Brand>
                    <Navbar.Collapse className='justify-content-end'>
                        <BugReportModal />
                        <Dropdown>
                            <Dropdown.Toggle variant='dark'>Configurações</Dropdown.Toggle>
                            <Dropdown.Menu align='right' data-testid='navbar-dropdown'>
                                <Dropdown.ItemText>
                                    <FormCheck 
                                        type='switch'
                                        label='Dark Mode'
                                        id='darkMode'
                                        onClick={e => this.props.darkModeHandler(e.target.checked)} 
                                        checked={Number(localStorage.getItem('@legal-cases/darkMode'))}
                                        readOnly
                                    />
                                </Dropdown.ItemText>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Navbar.Collapse>
                </Navbar>
            </>
        );

    }
}

export default Nav;