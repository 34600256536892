import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import { QuizzCategory, QuizzBoardScore, Loading, Error, ActionButton } from "../components";
import { ReactComponent as FileEarmark } from "./../images/icons/file-earmark.svg";
import { findQuizzesByCategoryCase } from "../http";

function Quizz(props) {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = props.match.params;

  useEffect(() => {
    findQuizzesByCategoryCase(id).then(({ data, status }) => {
      status === "error" ? setError(data) : setCategories(data);
      setLoading(false);
    });
  }, [id]);

  return loading ? (
    <Loading animation="grow" />
  ) : error ? (
    <Error title="Oops... Algo deu errado!" message={error} />
  ) : (
    <>
      <Row>
        <Col>
          {Object.values(categories).map((category) => (
            <QuizzCategory
              key={`category-${category.id}`}
              category={category.nome}
              quizzes={category.quizzes}
            />
          ))}
        </Col>
        <Col>
          <QuizzBoardScore categories={Object.values(categories)} />
        </Col>
      </Row>
      <Link key={`button-petition`} to={`/case/${id}/petition`}>
        <ActionButton color="dark" tooltip="Petição" icon={<FileEarmark />} />
      </Link>
    </>
  );
}

export default Quizz;
