import React, { useEffect, useState } from 'react'
import { Case, Loading, Error } from './../components'
import { Row, Col, Form } from 'react-bootstrap'
import SearchResults from 'react-filter-search'
import { Link } from 'react-router-dom'
import { findCases } from './../http'

function HomeCategory(props) {
  const [cases, setCases] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    findCases().then(({ data, status }) => {
      status === 'error' && setError(data)

      const { id } = props.match.params
      const category = data.filter(category => category.id === id)[0]

      setCases(category.casos)
      setLoading(false)
    })
  }, [props.match.params])

  if (loading) {
    return <Loading animation='grow' />
  }

  if (error) {
    return <Error title='Oops... Algo deu errado!' message={error} />
  }

  return (
    <div>
      <Form className='mb-5'>
        <Form.Control
          type='text'
          placeholder='Pesquisar'
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
      </Form>
      <SearchResults
        value={search}
        data={cases}
        renderResults={results => (
          <Row>
            <Col>
              <div className='d-flex align-items-stretch'>
                {results.map((caso) => {
                  if (caso.descricao.includes(search) || caso.titulo.includes(search)) {
                    return (
                      <Link
                        key={`link-case-${caso.id}`}
                        to={`/case/${caso.id}`}
                        className='text-decoration-none'
                      >
                        <Case
                          key={`case-${caso.id}`}
                          title={caso.titulo}
                          description={caso.descricao}
                          image={caso.imagem}
                        />
                      </Link>
                    )
                  }
                })}
              </div>
            </Col>
          </Row>
        )}
      />
    </div>
  )
}

export default HomeCategory
