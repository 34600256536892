import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Quizz as QuizzComponent, Loading, Error, ConfirmAlert } from "../components";
import { findQuizz } from "../http";

function Quizz(props) {
  const [error, setError] = useState(null);
  const [quizz, setQuizz] = useState({});
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [validated, setValidated] = useState(false);
  const [openUsernamePrompt, setOpenUsernamePrompt] = useState(true);
  const { id } = props.match.params;

  sessionStorage.clear();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  useEffect(() => {
    findQuizz(id).then(({ data, status }) => {
      status === "error" ? setError(data) : setQuizz(data);
      setLoading(false);
    });
    const user = localStorage.getItem('@legal-cases/username')
    console.log(user);
    user && setOpenUsernamePrompt(false)
  }, [id]);

  return loading ? (
    <Loading animation="grow" />
  ) : error ? (
    <Error title="Oops... Algo deu errado!" message={error} />
  ) : (
        <>
          <ConfirmAlert
            open={openUsernamePrompt}
            title="Identificação"
            body={
              <>
                <h4>Por Favor, digite seu nome abaixo: </h4>
                <Form.Control formNoValidate validated={validated} onSubmit={handleSubmit} onChange={({ currentTarget }) => setUsername(currentTarget.value)} placeholder="Digite seu nome..."  required isInvalid/>
              </>
            }
            confirm="Confirmar"
            onConfirm={() => {
              if (!username) {
                return;
            }
              localStorage.setItem("@legal-cases/username", username);
              setOpenUsernamePrompt(false);
              console.log(localStorage);
            }}
          />
          <QuizzComponent data={quizz} />
        </>
      );
}

export default Quizz;
