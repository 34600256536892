import React from "react";
import { FormCheck, FormControl } from "react-bootstrap";

function Alternative(props) {
  return props.type === "radio" || props.type === "checkbox" ? (
    <FormCheck
      key={`alternative-${props.id}`}
      id={`alternative-${props.id}`}
      data-id={props.id}
      type={props.type}
      label={props.description}
      name="alternative"
      value={props.description}
      onChange={(e) => props.handleChange(e, props.feedback)}
      required
    ></FormCheck>
  ) : (
    <FormControl
      type={props.type}
      onChange={(e) => props.handleChange(e, props.feedback)}
      required
    />
  );
}

export default Alternative;
